import React, { useRef, useState, useEffect } from 'react';
import { Registry } from '../../FormBuilderLibrary';
import FormHelperText from '@mui/material/FormHelperText';
import { stringToHTML } from '../utils';
import { getChipColor, getChipTextColor } from '../../../utils/utilFunctions';

import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getElementIcon } from '../utils';
import StyledWrapper from './style';
import Tooltip from '@mui/material/Tooltip';

export const MyTextField = React.forwardRef((props, ref) => {
	const {
		name,
		defaultValue,
		disabled,
		data,
		isFormBuilder,
		sx,
		isElementId = false,
		onChange,
		onBlur,
		onKeyDown,
		className,
		FormControlClassName = '',
		id,
		multiline = false,
		color,
		minRows,
		rows,
		InputProps,
		InputLabelProps,
		isLink = false,
		type
	} = props;

	const parsedLabel = React.useMemo(() => stringToHTML(props?.data?.label), [props?.data?.label]);
	const parsedHelperText = React.useMemo(
		() => stringToHTML(props?.data?.help_message),
		[props?.data?.help_message]
	);
	const styling = sx ? sx : { width: '100%' };

	const customInputStyle = isElementId
		? {
				width: '100%',
				minHeight: '56px',
				backgroundColor: '#F8F8F8',
				border: '1px solid rgb(196, 196, 196)'
		  }
		: { width: '100%', minHeight: '56px' };

	const isCoreInfoApplication = data?.custom_options?.includes('core-application');
	const [isOverflowing, setIsOverflowing] = useState(false);
	const labelRef = useRef(null);

	useEffect(() => {
		// ARM-397. The form elements initially take up the entire width of the screen and then are resized to fit into the proper row size. This setTimeout adds a delay so we do this calculation after the form elements are set to their proper row width. Without this the scroll width and clientWidth will be the same on screens with a width of 2000px and above
		setTimeout(() => {
			if (labelRef.current) {
				setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
			}
		}, 100);
	});

	return (
		<>
			{!disabled && !data?.custom_options?.includes('completely-disabled') ? (
				<FormControl sx={styling} variant="outlined" name={name} className={FormControlClassName}>
					<Tooltip
						arrow
						placement="top"
						title={isOverflowing ? `${props?.data?.label}` : ''}
						enterTouchDelay={0}
					>
						<StyledWrapper.CustomLabel
							value={defaultValue}
							disabled={disabled}
							required={props?.data?.required}
							htmlFor={id ? id : 'outlined-error-helper-text'}
						>
							<StyledWrapper.LabelContents ref={labelRef}>
								{isFormBuilder && getElementIcon(props.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<span>
										{' '}
										{props.data?.unique_identifier
											? `(ff-${props.data?.unique_identifier})`
											: `(tmp-${props.data?.temp_order})`}
									</span>
								)}
							</StyledWrapper.LabelContents>
						</StyledWrapper.CustomLabel>
					</Tooltip>
					<StyledWrapper.CustomInput
						error={props?.data?.error}
						id={id ? id : 'outlined-error-helper-text'}
						required={props?.data?.required}
						inputRef={ref}
						disabled={disabled}
						defaultValue={defaultValue}
						style={{ width: '100%', minHeight: '56px' }}
						multiline={multiline}
						minRows={minRows}
						rows={rows}
						className={className}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
						isLink={isLink}
						type={type ? type : 'input'}
						color={color}
						InputProps={InputProps}
						InputLabelProps={InputLabelProps}
					/>
					<FormHelperText error={props?.data?.error}>
						{props?.data?.error
							? props?.data?.error?.error
							: parsedHelperText?.[0]
							? parsedHelperText
							: props?.data?.help_message}
					</FormHelperText>
				</FormControl>
			) : (
				<FormControl sx={styling} variant="outlined" name={name}>
					<Tooltip
						arrow
						placement="top"
						title={isOverflowing ? `${props?.data?.label}` : ''}
						enterTouchDelay={0}
					>
						<StyledWrapper.CustomLabel
							value={defaultValue}
							disabled={
								disabled == 'disabled' || data?.custom_options?.includes('completely-disabled')
							}
							required={props?.data?.required}
							htmlFor={id ? id : 'outlined-adornment-password'}
						>
							<StyledWrapper.LabelContents ref={labelRef}>
								{isFormBuilder && getElementIcon(props?.data?.key)}
								{parsedLabel?.[0] ? parsedLabel : props?.data?.label}
								{isFormBuilder && (
									<span>
										{' '}
										{props?.data?.unique_identifier
											? `(ff-${props?.data?.unique_identifier})`
											: `(tmp-${props?.data?.temp_order})`}
									</span>
								)}
							</StyledWrapper.LabelContents>
						</StyledWrapper.CustomLabel>
					</Tooltip>
					<StyledWrapper.CustomInput
						error={props?.data?.error}
						id={id ? id : 'outlined-adornment-password'}
						name={name}
						inputRef={ref}
						disabled={disabled || data?.custom_options?.includes('completely-disabled')}
						defaultValue={defaultValue}
						value={defaultValue}
						style={customInputStyle}
						multiline={multiline}
						minRows={minRows}
						rows={rows}
						className={`className ${isElementId ? 'elementId' : ''}`}
						onChange={onChange}
						onBlur={onBlur}
						onKeyDown={onKeyDown}
						isLink={isLink}
						type={type ? type : 'input'}
						color={color}
						InputLabelProps={InputLabelProps}
						InputProps={InputProps}
						inputComponent={() => {
							return (
								<div style={{ display: 'flex', gap: '10px' }}>
									{(props?.data?.chip || isCoreInfoApplication) && (
										<Chip
											id={'programDetails-statusChip'}
											data-testid={`programDetails-statusChip`}
											label={props?.data?.chip || 'Status'}
											sx={{
												backgroundColor: getChipColor(props?.data?.chip || 'Status'),
												color: getChipTextColor(props?.data?.chip),
												WebkitTextFillColor:
													props?.data?.chip === 'Unassigned' ? 'black' : 'white!important'
											}}
											size="small"
										/>
									)}
									<p
										className={isElementId ? 'elementId' : ''}
										style={{
											width: data?.isLink ? data?.customWidth || '48%' : '100%',
											textOverflow: data?.isLink ? 'ellipsis' : 'none',
											overflow: 'hidden',
											whiteSpace: data?.isLink ? 'nowrap' : 'auto',
											margin: '0px'
										}}
									>
										{defaultValue ? defaultValue : isCoreInfoApplication && '[..]'}
									</p>

									{data?.isLink && (
										<IconButton
											onClick={() => {
												navigator.clipboard.writeText(defaultValue);
											}}
											aria-label="fingerprint"
											color="inherit"
											style={{ padding: '0px' }}
										>
											<ContentCopyIcon />
										</IconButton>
									)}
									{data?.showWarningIcon && data.warningIconComponent}
								</div>
							);
						}}
					/>
					<FormHelperText error={props?.data?.error}>
						{props?.data?.error
							? props?.data?.error?.error
							: parsedHelperText?.[0]
							? parsedHelperText
							: props?.data?.help_message}
					</FormHelperText>
				</FormControl>
			)}
		</>
	);
});

MyTextField.displayName = 'MUI_TextField';
Registry.register('MUI_TextField', MyTextField);
